import { template as template_f59f3f5fc8da4ffdb7863088a537e1e1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f59f3f5fc8da4ffdb7863088a537e1e1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
