import { template as template_1d0407574c20466db9a6e3d7a42ffa37 } from "@ember/template-compiler";
const FKLabel = template_1d0407574c20466db9a6e3d7a42ffa37(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
