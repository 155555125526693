import { template as template_6b0deb8b82fe454685890489d41cbddf } from "@ember/template-compiler";
const FKText = template_6b0deb8b82fe454685890489d41cbddf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
